import { HOSTS } from '@grepp/shared-services/envs/host';

const customHosts = (() => {
  if (process.env.NODE_ENV === 'development') {
    return {
      ...HOSTS,
      PROGRAMMERS: 'http://localhost:18080',
      PROGRAMMERS_WS: 'wss://main-ws.alpha.programmers.co.kr',
      ACCOUNT: 'http://localhost:18081',
      SCHOOL: 'https://main-school.alpha.programmers.co.kr',
      CAMPUS: 'https://campus-alpha.programmers.co.kr',
      CAREER: 'https://main-career.alpha.programmers.co.kr',
      COMMUNITY: 'https://community.alpha.programmers.co.kr',
      QNA: 'https://qna.alpha.programmers.co.kr',
      CERTI: 'https://certi.alpha.programmers.co.kr/',
      BUSINESS: 'https://main-business.alpha.programmers.co.kr',
      MINERVA: 'https://minerva-main.alpha.programmers.co.kr',
      GNB_API_BASE: '',
    };
  } else
    return {
      ...HOSTS,
      GNB_API_BASE: HOSTS.PROGRAMMERS,
      ACCOUNT: HOSTS.PROGRAMMERS,
    };
})();

export const URLS = Object.freeze({
  ...customHosts,
  BUSINESS_SIGN_IN: `${customHosts.BUSINESS}/business/login`,

  SIGN_IN: `${customHosts.ACCOUNT}/account/sign_in`,
  SIGN_UP: `${customHosts.ACCOUNT}/account/sign_up`,

  SKILL_CHECK: `${customHosts.PROGRAMMERS}/skill_checks`,
  HOME: `${customHosts.PROGRAMMERS}`,
  PROFILE: `${customHosts.PROGRAMMERS}/users/profile`,
  PRIVACY: `${customHosts.PROGRAMMERS}/privacy`,
  NOTIFICATIONS: `${customHosts.PROGRAMMERS}/notifications`,
  TOS: `${customHosts.PROGRAMMERS}/tos`,

  RESUME: `${customHosts.CAREER}/resumes`,
  JOB: `${customHosts.CAREER}/job`,
  JOB_PROFILE_EDIT: `${customHosts.CAREER}/job_profiles/edit`,
  COMPETITIONS: `${customHosts.CAREER}/competitions`,
  PUBLIC_URL_SETTING: `${customHosts.CAREER}/job_profiles/public_setting`,

  CODING_TEST: `${customHosts.SCHOOL}/learn/challenges`,
  SOLVED_CODING_TEST: `${customHosts.SCHOOL}/learn/challenges?order=recent&page=1&statuses=solved`,
  FREE_FUNDING_COURSE: `${customHosts.SCHOOL}/learn/KDT`,
  RECOMMENDED_COURSE: `${customHosts.SCHOOL}/learn?tag=추천 강의`,
  MY_LEARNING_COURSE: `${customHosts.SCHOOL}/my-courses/learning`,
  EDU_CODING_TEST_PAGE: `${customHosts.SCHOOL}/pages/edu-codingtest-page`,

  BLOG: `${customHosts.COMMUNITY}/blog`,
  NOTICE: `${customHosts.PROGRAMMERS}/notices`,
});
